.App {
  text-align: center;
  padding:15px 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App {
  
  padding-bottom:40px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.select-condensed .MuiSelect-root.MuiSelect-select {
  padding-top:10px;
  padding-bottom:10px;
}
.MuiLink-root > div {
  float:left;
  padding-top:4px;
}
.MuiLink-root > svg {
  float:left;
  margin-right:10px;
}

.creditUnionCard {
  padding:10px;
  height:150px;
}
.creditUnionCard img {
  width:auto;
  height:50px;
  padding:8px;
  margin:auto;
}
.creditUnionCard .card-actions {
  float:right;
}
.creditUnionCard:hover {
  background:#f9f9f9;
  cursor:pointer;
}
.CreditUnion .logo {
  height:100px;
  
}
.mam-admin-header {
  text-align:center;
  padding:15px;
}
a {
  cursor:pointer;
}
.tabPanel {
  padding:15px;
  background:#f9f9f9;
}
.Input {
  margin-bottom:25px;
}
.DialogActions {
  padding: 8px 24px 16px 24px !important;
}
.paper {
  padding:15px;
}
.text-area {
    border-radius:5px;
}
.selectLabelCorrection{
  top:-7px !important;
  left:15px !important;
}
.config-dialog .MuiDialog-container {
  max-width: 1000px;
  margin:auto;
}

.mbtn {
  padding-left:15px;
  padding-right:0;
  color:#fff;
  cursor:pointer;
  border-radius:3px;
  border:solid 2px #d3d3d3;
}
.mbtn-edit {
  background:#28a745!important;
}
.mbtn-remove {
  background:#dc3545!important;
}
.mbtn-arrow-up {
  background:#6c757d!important;
}
.mbtn-arrow-down {
  background:#6c757d!important;
}