body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#Toast {
  position:fixed;
  display: none;
  animation-name: toast;
  animation-duration: .5s;
  text-align: center;
  left:0;
  right:0;
}
#ToastContent {
  width: 200px;
  margin: auto;
  padding: 10px;
  padding-top:3px;
  border-radius: 0 0 5px 5px;
}
#Toast.toast-danger #ToastContent {
  background-color:#c82333;
  color:#fff;
}
#Toast.toast-warning #ToastContent {
  background-color:#e0a800;
  color:#212529;
}
#Toast.toast-success #ToastContent {
  background-color:#28a745;
  color:#fff;
}
@keyframes toast {
  0% { top: -40px; }
  100% { top: 0px; }
}
.App {
  position:relative;
}
.curtain {
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index:1;
  background:rgba(100,100,100,.25);
}
.product-edit {
  position: relative;
  z-index: 2;
  padding: 30px !important;
  background:#fff;
}

.mamConfigTab {
  text-align: left;
  margin-top:20px;
}
.mamConfigTab textarea {
  font-family: courier;
}
.text-success {
  color:green;
}
.text-danger {
  color:red;
}